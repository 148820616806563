module.exports = {
  HOME_URL: 'https://new.myjobs.ge',
  API_URL: 'https://api.myjobs.ge',
  AUTH_URL: 'https://auth.tnet.ge/ka/user/login/?Continue=',
  LOGOUT_URL: 'https://auth.tnet.ge/ka/user/logout/?Continue=',
  API_HOSTNAME: 'myjobs.ge',
  APP_PORT: '3000',
  ACCESS_TOKEN_NAME: 'AccessToken',
  TOKEN_TIME_MINUTES: 600,
  TOKEN_EXPIRE_TIME_NAME: 'trtn',
  NODE_ENV: 'production',
  AUTH_PAGE: '/',
  HR_URL: 'https://hr.myjobs.ge',
  STATIC_URL: 'https://static.my.ge',
  TNET_ACCOUNTS_URL: 'https://accounts.tnet.ge',
NODE_ENV:'production',
  SENTRY_AUTH_TOKEN: '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'

}
