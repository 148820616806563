import React, { createContext, useContext, useEffect, useState } from "react"
import { ACCESS_TOKEN_NAME } from "src/env"
import { ITokenTnetUser } from "src/types/commonTypes"
import Cookie from "src/utils/configs/Cookie"
import { getUserData } from "src/utils/configs/jwt"

export interface User_Context {
  isConnected: boolean
  tnetUserData: ITokenTnetUser | null | undefined
}
// Create a context for the user
const UserContext = createContext<User_Context>({
  isConnected: false,
  tnetUserData: undefined
})

export const UserProvider = ({ children }: any) => {
  const [tnetUserData, setConnectedUser] = useState<ITokenTnetUser | null>(null)

  const AccessToken = Cookie.get(ACCESS_TOKEN_NAME)
  useEffect(() => {
    if (!AccessToken) {
      setConnectedUser(null)
    } else {
      const user = getUserData(AccessToken)
      // ConnectedUser state
      setConnectedUser(user)
    }
  }, [AccessToken]) // empty dependency array ensures this runs only once

  const context = {
    tnetUserData,
    isConnected: !!AccessToken
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

// Create a custom hook to use the UserContext
export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    return null
  }

  return context
}
